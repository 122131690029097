/* eslint-disable */
import "./App.css";
import React, { act, useEffect, useState } from "react";
import ThemeProvider from "theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import Home from "pages/Home";
import Menu from "pages/Menu";
import Detail from "pages/Detail";
import Order from "pages/Order";
import Info from "pages/Info";
import Pay from "pages/Pay";
import Complete from "pages/Complete";

// notistack
import { SnackbarProvider } from "notistack";
import { StyledMaterialDesignContent } from "utils/attrs";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// translation
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import Cookie from "js-cookie";
import { CircularProgress, Container, Box, Typography } from "@mui/material";

import Entrance from "pages/Entrance";
import { CommonModal } from "components";

function App() {
	const [id, setId] = useState(Cookie.get("Kiosk_ID"));

	const [timeLeft, setTimeLeft] = useState(15); // 타이머 값
	const [counter, setCounter] = useState(10); // 대기화면 카운트
	const [isRunning, setIsRunning] = useState(false); // 타이머 실행 상태
	const [standBy, setStandBy] = useState(false); // 대기화면 모달

	// 타이머 시작
	const startTimer = () => {
		setIsRunning(true);
	};

	// 타이머 초기화
	const resetTimer = () => {
		setTimeLeft(15); // 타이머 값 초기화
		setCounter(10); // 대기화면 카운트 값 초기화
		setIsRunning(false); // 타이머 중지
		setStandBy(false); // 대기화면 모달 닫기
	};

	// 동작 없을시 메인화면으로 이동하는 타이머
	useEffect(() => {
		let timer;
		let count;

		if (isRunning && timeLeft > 0) {
			// 타이머 실행
			timer = setTimeout(() => {
				setTimeLeft((prev) => prev - 1);
			}, 1000);
		} else if (timeLeft === 0) {
			// 타이머 종료
			setIsRunning(false);

			// 대기화면 모달 활성화
			setStandBy(true);

			count = setTimeout(() => {
				setCounter((prev) => prev - 1);
			}, 1000);
		}
		if (counter <= 0) {
			window.location.href = `/?code=${id}`;
		}

		return () => {
			// 컴포넌트 언마운트 또는 상태 변경 시 타이머 정리
			clearTimeout(timer);
			clearTimeout(count);
		};
	}, [timeLeft, isRunning, counter]);

	useEffect(() => {
		window.addEventListener("click", () => {
			if (window.location.pathname === "/") {
				resetTimer();
			} else {
				try {
					resetTimer();
				} finally {
					startTimer();
				}
			}
		});
	}, []);

	// 키오스크 ID 체크
	useEffect(() => {
		if (typeof window !== "undefined") {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const queryValue = urlParams.get("code");
			if (process.env.NODE_ENV === "production") {
				// 운영
				if (!queryValue) {
					Cookie.remove("Kiosk_ID");
					setId(null);
				} else {
					Cookie.set("Kiosk_ID", queryValue);
					localStorage.setItem("Kiosk_ID", queryValue);
					setId(queryValue);
				}
			} else {
				// 개발
				Cookie.set("Kiosk_ID", queryValue || "QS23-KIOSK-14");
				localStorage.setItem("Kiosk_ID", queryValue || "QS23-KIOSK-14");
				setId(queryValue || "QS23-KIOSK-14");
			}
		}
	}, [window]);

	return (
		<I18nextProvider i18n={i18n}>
			<>
				{id ? (
					<BrowserRouter>
						<SnackbarProvider
							autoHideDuration={2000}
							maxSnack={1}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							Components={{
								default: StyledMaterialDesignContent,
								success: StyledMaterialDesignContent,
								error: StyledMaterialDesignContent,
								warning: StyledMaterialDesignContent,
							}}
						>
							<ThemeProvider>
								<Routes>
									<Route path="/" element={<Home />} />
									<Route path="/menus" element={<Menu />} />
									<Route path="/detail/:id" element={<Detail />} />
									<Route path="/order" element={<Order />} />
									<Route path="/info" element={<Info />} />
									<Route path="/pay" element={<Pay />} />
									<Route path="/complete" element={<Complete />} />

									{/* --NFC-- */}
									{/* 입장 */}
									<Route path="/entrance" element={<Entrance />} />
								</Routes>

								{/* ----------- 대기화면 모달 ----------- */}
								<CommonModal
									open={standBy}
									footer={{
										label: "계속하기",
									}}
									subFooter={{
										label: "초기 화면으로",
										onClick: () => {
											window.location.href = `/?code=${id}`;
										},
									}}
								>
									<Box sx={{ textAlign: "center", py: 2 }}>
										<Typography variant="h3" fontSize={24} mb={2}>
											{"알림"}
										</Typography>
										<Typography fontSize={20} color="grey.middle">
											{"장시간 사용이 없습니다."}
											<br />
											{`${counter}초 후에 초기 화면으로 전환되며`}
											<br />
											{'장바구니는 초기화 됩니다.'}
										</Typography>
									</Box>
								</CommonModal>
								{/* ----------- 대기화면 모달 ----------- */}
							</ThemeProvider>
						</SnackbarProvider>
					</BrowserRouter>
				) : (
					<Container sx={{ py: 5, display: "flex", justifyContent: "center" }}>
						<CircularProgress />
					</Container>
				)}
			</>
		</I18nextProvider>
	);
}

export default App;
