import { Grid, Container, Typography, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import Api from "utils/wApi/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Logo, CommonModal } from "components";
import AdMenu from "../components/ad/ad-menu";

import useCart from "../store";
import Cookie from "js-cookie";

import StandByImg from "assets/image/kiosk_standby.png";
import QsBannerImg from "assets/image/qs_banner.png";

// ----------------------------------------------------------------------

export default function HomePage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const {
		festival,
		cart,
		category,
		setCategory,
		setActive,
		setFestival,
		removeAllCart,
		setCountdown,
	} = useCart((state) => state);

	// 카테고리 없을시 모달 상태
	const [open, setOpen] = useState(false);
	// 대기화면 온오프
	const [standBy, setStandBy] = useState(true);

	async function init() {
		setStandBy(true);

		console.log("Kiosk_ID: ", Cookie.get("Kiosk_ID"));

		if (!Cookie.get("Kiosk_ID")) return;
		Cookie.set("qx-extended", "");

		try {
			const {
				data: { id, name },
			} = await Api.festival();
			const { data } = await Api.getCategory(null, id);
			setFestival(name);
			setCategory(data);
		} catch (error) {
			// 안내 모달창 열기
			setOpen(true);
		}

		removeAllCart();
	}

	useEffect(() => {
		init();
	}, []);
	return standBy ? (
		// 대기화면
		<Box
			sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
		>
			<Box
				sx={{
					width: "800px",
					height: "1120px",
					background: `url(${StandByImg}) no-repeat center / cover`,
				}}
				alt="kiosk standby img"
				onClick={() => setStandBy(false)}
			/>
			<Typography
				variant="h2"
				sx={{ textAlign: "center", bgcolor: "grey.600", py: 5, width: "100%" }}
			>
				주문하시려면
				<br />
				화면을 터치해 주세요.
			</Typography>
		</Box>
	) : (
		<Container
			title="home"
			sx={{
				px: "0 !important",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				height: "100vh",
				overflow: "hidden",
			}}
		>
			<Box sx={{ px: 3 }}>
				<Box
					sx={{
						px: 2,
						py: 3,
						display: "flex",
						alignItems: "center",
						height: 92,
					}}
				>
					<Logo />
				</Box>

				<Container>
					{/* 상품 카테고리 */}
					<Grid spacing={3} container>
						{category?.map((item, i) => (
							<Grid item xs={6} key={i}>
								<Box
									sx={{
										backgroundColor: "grey.400",
										width: "100%",
										paddingBottom: "100%",
										borderRadius: 1,
										cursor: "pointer",
										background: `center/contain no-repeat url(${item.kioskIcon})`,
									}}
									onClick={() => {
										setActive(item.idCategory);
										navigate(`/menus`);
										setCountdown(5);
									}}
								/>
							</Grid>
						))}
					</Grid>
				</Container>

				{/* ----------- 연결된 카테고리가 없을 경우 ----------- */}
				<CommonModal
					open={open}
					footer={{ label: "닫기", onClick: () => setOpen(false) }}
				>
					<Box sx={{ textAlign: "center", py: 2 }}>
						<Typography variant="h3" fontSize={24} mb={2}>
							{"알림"}
						</Typography>
						<Typography fontSize={20} color="grey.middle">
							{"선택 가능한 카테고리가 없습니다."}
						</Typography>
					</Box>
				</CommonModal>
				{/* ----------- 연결된 카테고리가 없을 경우 ----------- */}
			</Box>

			<Box>
				{/* 광고 모달 : ad_4 */}
				<AdMenu />
				{/* 광고 모달 : ad_4 */}
			</Box>
		</Container>
	);
}
